<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <h3 class="text-dark p-2">
          รายงานประวัติลูกค้า
        </h3>
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="validationCustomUsername">ประวัติการเติมล่าสุด</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                >วันที่</span>
              </div>
              <flat-pickr
                v-model="dateStartTopup"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                >ถึง</span>
              </div>
              <flat-pickr
                v-model="dateEndTopup"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <div class="col-md-4">
            <label for="validationCustomUsername">ประวัติการถอนล่าสุด</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                >วันที่</span>
              </div>
              <flat-pickr
                v-model="dateStartWithdraw"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                >ถึง</span>
              </div>
              <flat-pickr
                v-model="dateEndWithdraw"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <div class="col-md-4">
            <label for="validationCustomUsername">ประวัติการเข้าใช้งานล่าสุด</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                >วันที่</span>
              </div>
              <flat-pickr
                v-model="dateStartSignIn"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                >ถึง</span>
              </div>
              <flat-pickr
                v-model="dateEndSignIn"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>

          <div class="col-md-2 mt-1">
            <div class="form-group">
              <label for="username">รหัสสมาชิก</label>
              <b-form-input
                v-model="searchUsername"
                placeholder="รหัสสมาชิก"
              />
            </div>
          </div>
          <div class="col-md-2 mt-1">
            <div class="form-group">
              <label for="username">ยอดการเติมล่าสุดมากกว่า</label>
              <b-form-input
                v-model="searchTopup"
                placeholder="ยอดการเติมล่าสุดมากกว่า"
              />
            </div>
          </div>
          <div class="col-md-2 mt-1">
            <div class="form-group">
              <label for="username">ยอดการถอนล่าสุดมากกว่า</label>
              <b-form-input
                v-model="searchWithdraw"
                placeholder="ยอดการถอนล่าสุดมากกว่า"
              />
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="btn btn-gradient-primary"
              @click="GetData()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
            <button
              class="btn btn-gradient-dark ml-1"
              @click="ClearSearch()"
            >
              ล้างค่า
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body />
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="font-size:12px;"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>

        <template
          #head(amount_depositall)="data"
        >
          <div
            class="w-100"
            @click="GetData(1)"
          >
            <span
              class="cursor-pointer sorting"
              @click="SortIcon_depositall = !SortIcon_depositall"
            >{{ data.label.toUpperCase() }} <i
              v-if="SortIcon_depositall"
              class="fa fa-sort-up"
            />
              <i
                v-if="!SortIcon_depositall"
                class="fa fa-sort-down"
              /></span>
          </div>
        </template>

        <template
          #head(amount_deposit)="data"
        >
          <div
            class="w-100"
            @click="GetData(2)"
          >
            <span
              class="cursor-pointer sorting"
              @click=" SortIcon_deposit = !SortIcon_deposit"
            >{{ data.label.toUpperCase() }} <i
              v-if="SortIcon_deposit"
              class="fa fa-sort-up"
            />
              <i
                v-if="!SortIcon_deposit"
                class="fa fa-sort-down"
              /></span>
          </div>
        </template>

        <template
          #head(deposit_last)="data"
        >
          <div
            class="w-100"
            @click="GetData(3)"
          >
            <span
              class="cursor-pointer sorting"
              @click="SortIcon_deposit_last = !SortIcon_deposit_last"
            >{{ data.label.toUpperCase() }} <i
              v-if="SortIcon_deposit_last"
              class="fa fa-sort-up"
            />
              <i
                v-if="!SortIcon_deposit_last"
                class="fa fa-sort-down"
              /></span>
          </div>
        </template>

        <template
          #head(amount_withdrawall)="data"
        >
          <div
            class="w-100"
            @click="GetData(4)"
          >
            <span
              class="cursor-pointer sorting"
              @click="SortIcon_amount_withdrawall = !SortIcon_amount_withdrawall"
            >{{ data.label.toUpperCase() }} <i
              v-if="SortIcon_amount_withdrawall"
              class="fa fa-sort-up"
            />
              <i
                v-if="!SortIcon_amount_withdrawall"
                class="fa fa-sort-down"
              /></span>
          </div>
        </template>

        <template
          #head(amount_withdraw)="data"
        >
          <div
            class="w-100"
            @click="GetData(5)"
          >
            <span
              class="cursor-pointer sorting"
              @click="SortIcon_amount_withdraw = !SortIcon_amount_withdraw"
            >{{ data.label.toUpperCase() }} <i
              v-if="SortIcon_amount_withdraw"
              class="fa fa-sort-up"
            />
              <i
                v-if="!SortIcon_amount_withdraw"
                class="fa fa-sort-down"
              /></span>
          </div>
        </template>

        <template
          #head(withdraw_last)="data"
        >
          <div
            class="w-100"
            @click="GetData(6)"
          >
            <span
              class="cursor-pointer sorting"
              @click="SortIcon_withdraw_last = !SortIcon_withdraw_last"
            >{{ data.label.toUpperCase() }} <i
              v-if="SortIcon_withdraw_last"
              class="fa fa-sort-up"
            />
              <i
                v-if="!SortIcon_withdraw_last"
                class="fa fa-sort-down"
              /></span>
          </div>
        </template>

        <template #cell(wallet)="data">
          <div class="text-center">
            <b-spinner
              v-if="spinShow"
              variant="primary"
              small
            />
          </div>
          <div
            v-if="!spinShow"
            style="width: 130px !important;"
          >
            <div>
              เครดิตหลัก :
              <b-badge
                variant="light-primary"
              >
                {{ data.item.current_credit ? Commas(data.item.current_credit): 0 }}
              </b-badge>
            </div>
            <div>
              เครดิตในเกม :
              <b-badge
                variant="light-success"
              >
                {{ data.item.in_game_credit ? Commas(data.item.in_game_credit): 0 }}
              </b-badge>
            </div>
            <div>
              เครดิตลิมิต :
              <b-badge
                variant="light-warning"
              >
                {{ data.item.credit_limit ? Commas(data.item.credit_limit): 0 }}
              </b-badge>
            </div>
          </div>
        </template>
        <template #cell(amount_depositall)="data">
          {{ data.item.amount_depositall ? Commas(data.item.amount_depositall): 0 }}
        </template>
        <template #cell(amount_deposit)="data">
          {{ data.item.amount_deposit ? Commas(data.item.amount_deposit): 0 }}
        </template>
        <template #cell(amount_withdrawall)="data">
          {{ data.item.amount_withdrawall ? Commas(data.item.amount_withdrawall): 0 }}
        </template>
        <template #cell(amount_withdraw)="data">
          {{ data.item.amount_withdraw ? Commas(data.item.amount_withdraw): 0 }}
        </template>
        <template #cell(deposit_last)="data">
          {{ data.item.deposit_last ? time(data.item.deposit_last): null }}
        </template>
        <template #cell(withdraw_last)="data">
          {{ data.item.withdraw_last ? time(data.item.withdraw_last): null }}
        </template>
        <template #cell(stamplast_login)="data">
          {{ data.item.stamplast_login ? time(data.item.stamplast_login): null }}
        </template>
        <template #cell(status)="data">
          <div>
            <b-form-checkbox
              v-model="data.item.checkremark"
              :value="true"
              @change="CheckRemark(data.item)"
            >
              <font size="1">
                โทรแล้ว
              </font>
            </b-form-checkbox>
            <p class="text-danger">
              {{ data.item.byedit_remark }}
            </p>
          </div>
        </template>

        <template #cell(remark)="data">
          <div>
            <small
              class="text-warning text-center"
              style="font-size:10px;"
            >{{ data.item.remark }}</small>
            <i
              class="fa fa-edit ml-1"
              @click="itemremark(data.item)"
            />
            <!-- v-b-modal.modal-1 -->
          </div>
        </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-1"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      title="ข้อมูล"
      @ok.prevent="submit()"
    >
      <b-card-text>
        <b-form-textarea
          v-model="textAreaRemark"
          size="sm"
          placeholder="รายละเอียด"
        />
        <div
          v-if="editby"
          class="d-flex justify-content-end mt-1"
        >แก้ไขโดย : {{ editby }}
        </div>
      </b-card-text>
    </b-modal>
  </b-overlay>
</template>

<script>

import {
  // BAvatar,
  BBadge,
  // BButton,
  // BRow, BCol,
  // BInputGroupPrepend, BModal,
  BCard,
  BCardBody,
  BFormCheckbox,
  BFormGroup,
  // BInputGroup,
  BFormInput,
  BFormSelect,
  BIconController,
  BOverlay,
  BPagination,
  BSpinner,
  BTable,
  VBToggle,
  VBTooltip,
  BModal,
  BFormTextarea,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    // vSelect,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    // BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormCheckbox,
    BBadge,
    BSpinner,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      show: true,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      SortIcon_depositall: false,
      SortIcon_deposit: false,
      SortIcon_deposit_last: false,
      SortIcon_amount_withdrawall: false,
      SortIcon_amount_withdraw: false,
      SortIcon_withdraw_last: false,
      /* eslint-disable global-require */
      items: [],
      //   addNewDataSidebar: false,
      //   UserData: JSON.parse(localStorage.getItem('userData')),
      //   dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      //   dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),

      CheckBoxRemark: false,
      dateStartTopup: null, // ประวัติเติม
      dateEndTopup: null, // ประวัติเติม
      dateStartWithdraw: null, // ประวัติถอน
      dateEndWithdraw: null, // ประวัติถอน
      dateStartSignIn: null, // ประวัติเข้าใช้งาน
      dateEndSignIn: null, // ประวัติเข้าใช้งาน

      searchUsername: null, // ค้นหารหัสสมาชิก
      searchTopup: null, // ค้นหายอดการเติมล่าสุดมากกว่า
      searchWithdraw: null, // ค้นหายอดการถอนล่าสุดมากกว่า
      textAreaRemark: null,
      spinShow: true,
      dataremark: null,
      id: null,
      editby: null,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'index', label: 'NO.' },
        { key: 'username', label: 'รหัสสมาชิก', thStyle: { width: '1%' } },
        { key: 'tel', label: 'เบอร์โทร', thStyle: { width: '10%' } },
        { key: 'invited_by_name', label: 'เซียน', thStyle: { width: '10%' } },
        {
          key: 'wallet', label: 'กระเป๋าเงิน', thClass: 'text-center',
        },
        { key: 'amount_depositall', label: 'ฝากสะสม', thStyle: { width: '5%' } },
        { key: 'amount_deposit', label: 'ฝากล่าสุด', thStyle: { width: '5%' } },
        {
          key: 'deposit_last', label: 'ฝากล่าสุดเมื่อ', thStyle: { width: '5%' },
        },
        { key: 'amount_withdrawall', label: 'ถอนสะสม', thStyle: { width: '5%' } },
        { key: 'amount_withdraw', label: 'ถอนล่าสุด', thStyle: { width: '5%' } },
        { key: 'withdraw_last', label: 'ถอนล่าสุดเมื่อ', thStyle: { width: '5%' } },
        { key: 'stamplast_login', label: 'เข้าใช้งานล่าสุด', thStyle: { width: '5%' } },
        { key: 'status', label: 'status', thStyle: { width: '5%' } },
        { key: 'remark', label: 'remark', thStyle: { width: '5%' } },
      ]
    },
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
    this.GetData()
    this.getbalance()
  },
  methods: {
    // handleSort(type) {
    //   console.log(`Sorting${type}`)
    // },
    async GetData(type) {
      this.show = true
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          dateStartTopup: this.dateStartTopup, // วันที่ประวัติการเติมล่าสุด
          dateEndTopup: this.dateEndTopup, // ถึง ประวัติการเติมล่าสุด
          dateStartWithdraw: this.dateStartWithdraw,
          dateEndWithdraw: this.dateEndWithdraw,
          dateStartSignIn: this.dateStartSignIn,
          dateEndSignIn: this.dateEndSignIn,
          searchUsername: this.searchUsername,
          searchTopup: this.searchTopup,
          searchWithdraw: this.searchWithdraw,
          type,
          SortIcon_depositall: this.SortIcon_depositall ? '1' : '-1',
          SortIcon_deposit: this.SortIcon_deposit ? '1' : '-1',
          SortIcon_deposit_last: this.SortIcon_deposit_last ? '1' : '-1',
          SortIcon_amount_withdrawall: this.SortIcon_amount_withdrawall ? '1' : '-1',
          SortIcon_amount_withdraw: this.SortIcon_amount_withdraw ? '1' : '-1',
          SortIcon_withdraw_last: this.SortIcon_withdraw_last ? '1' : '-1',
        }
        const { data: res } = await this.$http.get('/admin/GetDataCustomer', { params })
        res.data.forEach(ele => {
          // eslint-disable-next-line no-param-reassign
          ele.current_credit = 0
          // eslint-disable-next-line no-param-reassign
          ele.credit_limit = 0
          // eslint-disable-next-line no-param-reassign
          ele.in_game_credit = 0
          this.getbalance(ele.username)
        })
        this.items = res.data
        this.totalRows = res.total
        this.show = false
      } catch (err) {
        console.log(err)
        this.show = false
      }
      // this.items = [
      //   {
      //     username: '123456789',
      //     tel: '0999999999',
      //     zean: 'ตี๋',
      //     wallet: [200, 300, 1000],
      //     deposit: 500,
      //     depositLast: 100,
      //     depositLasttime: '02/02/2024 16:44:32',
      //     withdraw: 5000,
      //     withdrawLast: 200,
      //     withdrawLasttime: '02/02/2024 11:44:32',
      //     SigninLast: '01/02/2024 7:21:22',
      //     remark: 'admin007',
      //   },
      //   {
      //     username: '123456789',
      //     tel: '0999999999',
      //     zean: 'ตี๋',
      //     wallet: [200, 300, 1000],
      //     deposit: 500,
      //     depositLast: 100,
      //     depositLasttime: '05/02/2024 16:44:32',
      //     withdraw: 5000,
      //     withdrawLast: 200,
      //     withdrawLasttime: '01/02/2024 11:44:32',
      //     SigninLast: '05/02/2024 7:21:22',
      //     remark: 'admin007',
      //   },
      // ]

    //   await this.$http
    //     .get('/admin/loguser', { params })
    //     .then(response => {
    //       this.show = false
    //       this.onFiltered(response.data)
    //     })
    //     .catch(error => console.log(error))
    },
    getbalance(data) {
      this.spinShow = true
      const params = {
        username: data,
      }
      this.$http
        .get('/balance/getbalance', { params })
        .then(response => {
          if (response.data.status === 'success') {
            this.items.forEach(ele => {
              if (ele.username === data) {
                // eslint-disable-next-line no-param-reassign
                ele.current_credit = response.data.current_credit
                // eslint-disable-next-line no-param-reassign
                ele.credit_limit = response.data.credit_limit
                // eslint-disable-next-line no-param-reassign
                ele.in_game_credit = response.data.in_game_credit
              }
            })
            this.spinShow = false
          }
          // this.balance = response.data.balance
        })
        .catch(error => {
          console.log(error)
          this.spinShow = false
        })
    },
    ClearSearch() {
      this.dateStartTopup = null
      this.dateEndTopup = null
      this.dateStartWithdraw = null
      this.dateEndWithdraw = null
      this.dateStartSignIn = null
      this.dateEndSignIn = null
      this.searchUsername = null
      this.searchTopup = null
      this.searchWithdraw = null
    },
    onFiltered(filteredItems) {
      this.items = filteredItems.ResData
      this.totalRows = filteredItems.total
    },
    time(data) {
      return moment(data).format('DD/MM/YYYY')
    },
    CheckRemark(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการบันทึกข้อมูลการโทรใช่หรือไม่?', {
        title: 'ยืนยันการโทร',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
              checkremark: data.checkremark,
            }
            this.$http.post('/admin/Updateremark', obj)
              .then(() => {
                this.GetData()
                this.Success('บันทึกการโทรสำเร็จ')
              })
              .catch(err => {
                console.log(err)
                this.SwalError('บันทึกการโทรไม่สำเร็จ')
              })
          } else if (data.checkremark) {
            // eslint-disable-next-line no-param-reassign
            data.checkremark = false
          } else {
            // eslint-disable-next-line no-param-reassign
            data.checkremark = true
          }
        })
    },
    itemremark(data) {
      if (data) {
        // console.log(data)
        this.editby = data.byedit_text
        this.textAreaRemark = data.remark
        // eslint-disable-next-line no-underscore-dangle
        this.id = data._id
      } else {
        this.dataremark = null
        this.id = null
      }
      this.$bvModal.show('modal-1')
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    submit() {
      this.$bvModal.msgBoxConfirm('คุณต้องการบันทึกข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการบันทึก',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const obj = {
              remark: this.textAreaRemark,
              id: this.id,
            }
            this.$http.post('/admin/Updatetextremark', obj)
              .then(() => {
                this.GetData()
                this.Success('บันทึกสำเร็จ')
                this.$bvModal.hide('modal-1')
              })
              .catch(err => {
                console.log(err)
                this.SwalError('บันทึกไม่สำเร็จ')
              })
          }
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
